import { FC, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "hooks";

import { scrollTop } from "utils/commons";
import { Grid, Typography } from "@mui/material";
import { getBestSellersProducts } from "store/statistics.slice";

import {
  getCartState,
  getCartLoaders,
  getCartInformation,
  getProductsAreNewSelector,
} from "store/cart/cart.selectors";

import EmptyCart from "./components/EmptyCart";
import CartItemsList from "./components/CartItemsList";
import CustomLayout from "components/atoms/simple-layout";
import ViewMoreProducts from "./components/ViewMoreProducts";
import BackdropLoader from "components/atoms/backdrop-loader";
import AlertAmountExceded from "./components/AlertAmountExceded";
import ShoppingCartFees from "components/organisms/shopping-cart-fees";
import { getProductsAreNew } from "../../store/cart/cart.actions";

const ShoppingCart: FC = () => {
  const dispatch = useAppDispatch();

  const { isActiveCart } = useAppSelector(getCartState);

  const lsshoppingCart = useAppSelector((state) => state.lscart);
  const { bestSellers } = useAppSelector((state) => state.statistics);
  const { isLoggedIn } = useAppSelector((state) => state.userInformation);
  const productsAreNew = useAppSelector(getProductsAreNewSelector);
  const { id: cartId, items: cartItems } = useAppSelector(getCartInformation);

  const { exceptionLoader } = useAppSelector(getCartLoaders);

  useEffect(() => {
    scrollTop();
  }, []);

  useEffect(() => {
    if (!bestSellers.length) dispatch(getBestSellersProducts());
  }, [dispatch, bestSellers.length]);

  useEffect(() => {
    if (!isLoggedIn && lsshoppingCart.items?.length) {
      dispatch(
        getProductsAreNew(lsshoppingCart.items.map((i) => i.product.asin))
      );
    }
  }, [isLoggedIn, lsshoppingCart, dispatch]);

  if (!isActiveCart && isLoggedIn) return <BackdropLoader />;

  if (
    (isLoggedIn && !cartItems?.length) ||
    (!isLoggedIn && !lsshoppingCart.items?.length)
  )
    return <EmptyCart />;

  return (
    <CustomLayout>
      {exceptionLoader && <BackdropLoader />}

      <Typography my={2} fontSize={18}>
        Mi carrito (
        {isLoggedIn
          ? cartItems.reduce((prev, curr) => prev + Number(curr.quantity), 0)
          : lsshoppingCart.items.reduce(
              (prev, curr) => prev + curr.quantity,
              0
            )}{" "}
        Items)
      </Typography>

      <Grid container spacing={{ xs: 1, md: 1 }} columnSpacing={3}>
        <Grid item xs={12}>
          <AlertAmountExceded />
        </Grid>

        <Grid item xs={12} md={8} lg={9}>
          <CartItemsList
            cartId={cartId}
            fetchFavorites={isLoggedIn}
            items={
              !isLoggedIn
                ? lsshoppingCart.items.map((i) => ({
                    ...i,
                    isNew:
                      productsAreNew.find((i2) => i.product.asin === i2.asin)
                        ?.isNew ?? false,
                  }))
                : cartItems
            }
          />
        </Grid>

        <Grid item sx={{ pl: 0, mt: 1 }} xs={12} md={4} lg={3}>
          <ShoppingCartFees showNavigation />
        </Grid>

        <Grid item sx={{ mt: 2 }} xs={12} md={12} lg={12}>
          <ViewMoreProducts />
        </Grid>
      </Grid>
    </CustomLayout>
  );
};

export default ShoppingCart;
