import { IProduct } from "./products.interfaces";

export interface IShoppingCartBack {
  id: string;
  total: number;
  currency: Currency;
  user: string | null;
  cartItems: ICartItem[];
}

export interface IProductIsNew {
  asin: string;
  isNew: boolean;
}

export interface IShoppingCart {
  id: string;
  total: number;
  currency: Currency;
  user: string | null;
  items: ICartItem[];
  cartItems?: ICartItem[];
}

export interface ILSShoppingCart {
  total: number;
  currency: Currency;
  items: ICartItem[];
}

export interface ICartItem {
  id: string;
  title: string;
  price: number;
  image: string;
  quantity: number;
  product: IProduct;
  productId: string;
  isNew: boolean;
}

export enum Currency {
  usd = "USD",
  uyu = "UYU",
}

export enum Locale {
  en = "EN",
  es = "ES",
}

export interface AddItemToCartBody {
  title: string;
  image: string;
  price: number;
  quantity: number;
  productId: string;
}

export interface EditCartBody {
  quantity: number;
  cartItemId: string;
}

export interface IFees {
  total: number;
  weight: number;
  freight: number;
  handling: number;
  realWeight: number;
  deliveryFee: number;
  feePuntoMall: number;
  productsTotal: number;
  francheseFees?: number;
  bigPriceDetected?: boolean;
  excededLimitImportationAmount: boolean;

  // exceptions for exceded limit importation quantity for each product
  excededQuantityLimit?: boolean;

  // only applicable with order up to 200USD
  iva?: number;
  extraFees?: number;
}

export interface CreateOrMergeShoppingCartDto {
  products: CartITemDto[];
}

export interface CartITemDto {
  price: number;
  quantity: number;
  productId: string;
}

export interface FeesDto {
  userId?: string;
  useFranchise: FrancheseType;
  deliveryMethod?: DeliveryMethodsFees;
  productsAsin?: { asin: string; quantity: number }[];
}

export enum DeliveryMethodsFees {
  PICKUP = "PICKUP",
  MONTEVIDEO = "MONTEVIDEO",
  OTHER_DEPARTMENTS = "OTHER_DEPARTMENTS",
}

export enum FrancheseType {
  USE = "USE",
  NOT_USE = "NOT_USE",
  NOT_APPLICABLE = "NOT_APPLICABLE",
}

export interface ICartException {
  message: string;
  productId: string;
  available: boolean;
  priceUpdated: boolean;
}

export interface ICartAvailabilityResponse {
  cart: IShoppingCart;
  exceptions: ICartException[];
}
