import { RootState } from "store";

export const getCartState = (state: RootState) => state.cart;
export const getCartLoaders = (state: RootState) => state.cart.loaders;
export const getCartInformation = (state: RootState) => state.cart.shoppingCart;
export const getProductsAreNewSelector = (state: RootState) =>
  state.cart.productsAreNew;

export const getTotalCartPrice = (state: RootState) =>
  state.cart.shoppingCart.total;

export const getCartProducts = (state: RootState) =>
  state.cart.shoppingCart.items;
