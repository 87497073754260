import * as Yup from "yup";

import { FC, useEffect } from "react";
import { scrollTop } from "utils/commons";
import { useNavigate } from "react-router";
import { yupResolver } from "@hookform/resolvers/yup";
import { FieldValues, useForm } from "react-hook-form";
import { useAppDispatch, useAppSelector } from "hooks";

import {
  Box,
  Grid,
  Divider,
  Checkbox,
  Typography,
  FormControlLabel,
} from "@mui/material";

import {
  GoogleLoginComponent,
  FacebookLoginComponent,
} from "./FormsComponents";

import theme from "styles/muiTheme";
import Input from "components/atoms/input";
import CustomButton from "components/atoms/custom-button";

import { SignupDto } from "interfaces/user.interfaces";
import { cleanUserSliceErrors, signupUser } from "store/user.slice";
import { SnackbarType } from "store/snackbars/snackbars.interfaces";
import { passwordMessage, passwordRegex } from "utils/forms/validations";
import { createSnackbarMessage } from "store/snackbars/snackbars.actions";

const SignUpPage: FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { isLoggedIn, isLoading } = useAppSelector(
    (state) => state.userInformation
  );

  const validationSchema = Yup.object().shape({
    firstName: Yup.string()
      .min(2, "El largo mínimo del nombre es de 2 caracteres")
      .required("Campo obligatorio"),
    lastName: Yup.string()
      .min(2, "El largo mínimo del apellido es de 2 caracteres")
      .required("Campo obligatorio"),
    email: Yup.string()
      .max(100, "El largo máximo del email es de 100 caracteres")
      .email("Ingresá un correo electrónico válido")
      .required("Campo obligatorio"),
    password: Yup.string()
      .max(100, "El largo máximo de la contraseña es de 100 caracteres")
      .required("Campo obligatorio")
      .matches(passwordRegex, passwordMessage),
    confirmPassword: Yup.string()
      .required("Campo obligatorio")
      .matches(passwordRegex, passwordMessage)
      .oneOf([Yup.ref("password")], "Las contraseñas no son iguales"),
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    dispatch(cleanUserSliceErrors());
  }, [dispatch]);

  useEffect(() => {
    scrollTop();
  }, []);

  useEffect(() => {
    if (isLoggedIn) navigate("/");
  }, [isLoggedIn, navigate]);

  const onSubmit = async (data: FieldValues) => {
    dispatch(cleanUserSliceErrors());

    dispatch(signupUser(data as SignupDto)).then((data: any) => {
      if (!data.error) {
        dispatch(
          createSnackbarMessage({
            message:
              "Registro exitoso, revise su correo electrónico para continuar.",
            snackbarType: SnackbarType.success,
            origin: { vertical: "top", horizontal: "center" },
          })
        );

        navigate("/auth/signin");
      } else {
        dispatch(
          createSnackbarMessage({
            snackbarType: SnackbarType.error,
            origin: { vertical: "top", horizontal: "center" },
            message:
              data.payload.code === 409
                ? "Este correo electrónico ya se encuentra en uso, intente con uno distinto"
                : "Ha ocurrido un error, revise sus datos e intente nuevamente",
          })
        );
      }
    });
  };

  return (
    <>
      <Typography
        fontSize={24}
        textAlign="center"
        fontWeight={500}
        marginBottom={2}
      >
        ¡Regístrate!
      </Typography>

      <Grid container columns={12} justifyContent="center">
        <Grid item xs={12} sm={10} md={8}>
          <GoogleLoginComponent />
        </Grid>

        <Grid item xs={12} sm={10} md={8}>
          <FacebookLoginComponent />
        </Grid>
      </Grid>

      <Divider sx={{ marginY: 2 }}>
        <Typography fontSize={14} fontWeight={500} color="#777577">
          O registrate con tu email
        </Typography>
      </Divider>

      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container columnSpacing={2}>
          <Grid item xs={12} sm={6}>
            <Input
              label="Nombre"
              name="firstName"
              control={control}
              error={errors.firstName}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <Input
              name="lastName"
              control={control}
              label="Apellido"
              error={errors.lastName}
            />
          </Grid>
        </Grid>

        <Input
          name="email"
          control={control}
          error={errors.email}
          label="Tu dirección de correo electrónico"
        />

        <Input
          name="password"
          type="password"
          label="Crear contraseña"
          control={control}
          error={errors.password}
        />

        <Input
          name="confirmPassword"
          type="password"
          label="Confirmar contraseña"
          control={control}
          error={errors.confirmPassword}
        />

        <Typography fontSize={12}>
          <Box component="span" sx={{ color: theme.palette.warning.main }}>
            *
          </Box>{" "}
          {passwordMessage}
        </Typography>

        <FormControlLabel
          sx={{ fontSize: 14, marginY: 2 }}
          control={<Checkbox defaultChecked />}
          label={<Typography fontSize={14}>Recibir Ofertas!</Typography>}
        />

        <CustomButton
          sx={{ height: "48px", width: "100%" }}
          variant="primary"
          disabled={isLoading}
          text="Regístrate"
          type="submit"
        />
      </form>

      <Divider sx={{ marginY: 2 }}>
        <Typography fontSize={14} fontWeight={500} color="#777577">
          ¿Ya tienes una cuenta?
        </Typography>
      </Divider>

      <CustomButton
        sx={{ height: "48px", width: "100%" }}
        variant="grey"
        onClick={() => navigate("/auth/signin")}
        text="Ingresar"
      />
    </>
  );
};

export default SignUpPage;
