export const passwordRegex =
  /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/;

export const passwordMessage =
  "La contraseña debe tener al menos 8 caracteres, una letra mayúscula, una minúscula y un número";

export const inputValidations = {
  onlyText: (message?: string) => ({
    value: /^[0-9a-zñáéíóúü ]+$/i,
    message: message || "Por favor ingrese solo palabras",
  }),

  onlyNumbers: (message?: string) => ({
    value: new RegExp("^[0-9]+$"),
    message: message || "Por favor ingrese solo números",
  }),

  email: () => ({
    value: /^\S+@\S+$/,
    message: "Por favor ingrese un email válido",
  }),

  password: (message?: string) => ({
    value: passwordRegex,
    message: message || passwordMessage,
  }),
};
